import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../../../utils/reduxActions';
import { publicCalculatorActions } from './reducer';
import paths from '../../../../config/paths'
import { Image, Dimmer, Loader } from 'semantic-ui-react';
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { getColonies, existPostalCode } from '../../../../utils/colonies'

//images carriers
import whiteImg from '../../../../assets/img/white-inabit.svg';
import expressImg from '../../../../assets/img/express-inabit.svg';
import DeliveryTruck from '../../../../assets/img/delivery-truck.svg';
import CalculatoSvg from '../../../../assets/img/calculator.svg'
import { PkgDetail } from '../../../../interfaces/general';


interface CalculatorFormInput {
  clientAddrOrig: {
    zipCode: string
    colonyName: string
  },
  clientAddrDest: {
    zipCode: string
    colonyName: string
  }
  amountOfPackets: string
  shipmentPktDetails: PkgDetail[]
}

const schema = yup.object({
  clientAddrOrig: yup.object({
    zipCode: yup.string()
      .min(5, 'El código postal debe constar de  min. 5 números')
      .max(5, 'El código postal debe constar de  max. 5 números')
      .required('Código postal de origen requerido'),
    colonyName: yup.string().required('Colonia de origen requerido'),
  }),
  clientAddrDest: yup.object({
    zipCode: yup.string()
      .min(5, 'El código postal debe constar de  min. 5 números')
      .max(5, 'El código postal debe constar de  max. 5 números')
      .required('Código postal de destino requerido'),
    colonyName: yup.string().required('Colonia de destino requerido'),
  }),
  amountOfPackets: yup.number().integer()
    .default(1)
    .min(1, 'Valor invalido: Rango de 1 - 25')
    .max(25, 'Solo se puede cotizar max 25 paquetes')
    .required('N° de paquetes requerido. Valor min 0'),
  shipmentPktDetails: yup.array().of(yup.object({
    quantity: yup.number()
      .required('Cantidad. Campo obligatorio')
      .min(1, 'Min. cantidad de paquetes permitidos 1')
      .max(10, 'Max. cantidad de paquetes 10'),
    weight: yup.number()
      .required('Peso. Campo requerido')
      .min(2, 'Min. peso requerido es de 2KG')
      .max(2000, 'Max. peso soportado de 2000 kg'),
    longShip: yup.number()
      .required('Largo. Campo obligatorio')
      .min(2, 'Min. 2 CM de largo permitido'),
    widthShip: yup.number()
      .required('Anchura. campo requerido')
      .min(2, 'Min. 2 CM de Ancho permitido'),
    highShip: yup.number()
      .required('Altura. campo requerido')
      .min(2, 'Min. 2 CM de altura permitido'),
    shpCode: yup.string().required()
  }))
}).required();

interface CalculatorProps {
  actions?: any;
  calculatorControls?: any;
  history?: any;
  calculatorResult?: any;
  branchOfficesRes?: any;
  profile?: any;
}

const carrierImage: { [key: string]: string } = {
  'EXPRESS': expressImg,
  'FEDEX': whiteImg,
}

const PublicCalculator: React.FC<CalculatorProps> = (props) => {
  const { actions, calculatorControls, history, calculatorResult } = props;
  const { register, handleSubmit, formState: { errors }, control, watch, setValue } = useForm<CalculatorFormInput>({
    resolver: yupResolver(schema)
  });
  const { fields, append, remove } = useFieldArray({ name: 'shipmentPktDetails', control });
  const numberOfPackets = parseInt(watch('amountOfPackets'))
  const clientAddrOrigZipCode = watch('clientAddrOrig.zipCode')
  const clientAddrDestZipCode = watch('clientAddrDest.zipCode')
  const [coloniesOrig, setColoniesOrig] = useState<string[]>([])
  const [coloniesDest, setColoniesDest] = useState<string[]>([])
  const [origData, setOrigData] = useState([])
  const [destData, setDestData] = useState([])

  useEffect(() => {
    const newValPkt = numberOfPackets || 1;
    const oldVal = fields.length;
    if (newValPkt > oldVal) {
      for (let i = oldVal; i < newValPkt; i++) {
        append({ sequence: 0, shpCode: '2', highShip: undefined, longShip: undefined, quantity: 1, weight: undefined, widthShip: undefined });
      }
    } else {
      for (let i = oldVal; i > newValPkt; i--) {
        remove(i - 1);
      }
    }

    const searchZipCode = async (zipcode: string, type: string) => {
      const res = await existPostalCode(zipcode)
      if (res.exists) {
        const data = getColonies(res.data)
        if (type === 'ORIGIN') { setColoniesOrig([...new Set(data)]); setOrigData(res.data) }
        if (type === 'DESTINATION') { setColoniesDest([...new Set(data)]); setDestData(res.data) }
      }
    }

    if (clientAddrOrigZipCode?.length === 5) searchZipCode(clientAddrOrigZipCode, 'ORIGIN')
    if (clientAddrDestZipCode?.length === 5) searchZipCode(clientAddrDestZipCode, 'DESTINATION')
  }, [append, fields, numberOfPackets, remove, setValue, clientAddrOrigZipCode, clientAddrDestZipCode]);

  const getQuote = async (data: CalculatorFormInput) => {
    actions.controlsChanged({ ...data, origData, destData })
    actions.getQuote({ ...data, origData, destData, history });
  }

  const onHandleSignUp = () => history.push(paths.SIGN_UP)
  const retryQuote = () => { actions.controlsChanged({ actionQuote: false }); }

  return (
    <div>
      <Dimmer active={calculatorControls.loading} inverted>
        <Loader inverted>Buscando servicios disponibles....</Loader>
      </Dimmer>
      <div className="grid bg-transparent md:bg-white lg:p-4 sm:p-2 py-2 rounded-xl">
        {
          calculatorControls.actionQuote
            ?
            <></>
            :
            <div className='grid w-full lg:w-7/12 m-auto'>
              <form onSubmit={handleSubmit(getQuote)} className="rounded-xl p-2 w-full">
                <h2 className="text-lg lg:text-2xl font-bold text-center">Llena los siguientes campos para conocer las tarifas disponibles.</h2>
                <hr className="mt-1 mb-6 md:min-w-full " />
                <div className="grid grid-cols-1">
                  {/* Origin postal Code */}
                  <div className='grid'>
                    <span className='text-xl font-bold mb-2'>Datos de origen: </span>
                    <div className='grid sm:grid-cols-1 lg:grid-cols-2 lg:space-x-1'>
                      <div className='grid'>
                        <input
                          className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${errors.clientAddrOrig?.zipCode ? 'border-red-600' : ''}`}
                          type="number"
                          maxLength={5}
                          {...register("clientAddrOrig.zipCode" as const, { maxLength: 5, required: true })}
                          placeholder='Código postal de origen'
                        />
                        <span className='text-red-600 text-xs font-semibold h-5'>{errors.clientAddrOrig?.zipCode?.message}</span>
                      </div>
                      <div className='grid'>
                        <select
                          style={{ width: '100%' }}
                          className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${errors.clientAddrOrig?.colonyName ? 'border-red-600' : ''}`}
                          {...register('clientAddrOrig.colonyName')}
                        >
                          <option hidden value="">Seleccionar Colonia</option>
                          {
                            coloniesOrig.map((option) => (
                              <option value={option} key={option} >{option}</option>
                            ))
                          }
                        </select>
                        <span className='text-red-600 text-xs font-semibold h-5'>{errors.clientAddrOrig?.colonyName?.message}</span>
                      </div>
                    </div>
                  </div>
                  {/* Destination postal Code */}
                  <div className='grid'>
                    <span className='text-xl font-bold mb-2'>Datos de destino: </span>
                    <div className='grid sm:grid-cols-1 lg:grid-cols-2 lg:space-x-2'>
                      <div className='grid'>
                        <input
                          className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${errors.clientAddrDest?.zipCode ? 'border-2 border-red-600' : ''}`}
                          type="number"
                          maxLength={5}
                          {...register("clientAddrDest.zipCode" as const, { maxLength: 5, required: true })}
                          placeholder='Código postal de destino'
                        />
                        <span className='text-red-600 text-xs font-semibold h-5'>{errors.clientAddrDest?.zipCode?.message}</span>
                      </div>
                      <div className='grid'>
                        <select
                          style={{ width: '100%' }}
                          className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${errors.clientAddrOrig?.colonyName ? 'border-red-600' : ''}`}
                          {...register('clientAddrDest.colonyName')}
                        >
                          <option hidden value="">Seleccionar Colonia</option>
                          {
                            coloniesDest.map((option) => (
                              <option value={option} key={option}>{option}</option>
                            ))
                          }
                        </select>
                        <span className='text-red-600 text-xs font-semibold h-5'>{errors.clientAddrDest?.colonyName?.message}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1">
                  <div className='grid'>
                    <label className="text-gray-500 font-bold mb-3">N° de paquetes (Max. 25)</label>
                    <input
                      className={`text-lg p-2 bg-transparent border-2 rounded-lg ${errors.amountOfPackets ? 'border-red-600' : ''}`}
                      type="number"
                      defaultValue={1}
                      {...register('amountOfPackets' as const, { min: 1, max: 10, required: true })}
                    />
                    <span className='text-red-600 font-semibold h-6'>
                      {errors.amountOfPackets?.type === 'typeError' ? 'Valor únicos entre 0 - 25' : errors.amountOfPackets?.message}
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:w-full overflow-auto">
                  <table className="table-auto">
                    <thead>
                      <tr>
                        <th className='border-2 border-gray-300 text-2xs h-16 text-center'>
                          <span className='uppercase'>
                            N° de Paquetes
                          </span>
                          <br />
                          <span className='text-gray-500'>
                            Max. 10
                          </span>
                        </th>
                        <th className='border-2 border-gray-300 text-2xs h-16 text-center'>
                          <span className='uppercase'>
                            Peso por paquete
                          </span>
                          <br />
                          <span className='text-gray-500'>
                            KG
                          </span></th>
                        <th className='border-2 border-gray-300 text-2xs h-16 text-center'>
                          <span className='uppercase'>
                            Dimensiones por paquete
                          </span>
                          <br />
                          <span className='text-gray-500'>
                            L × An × Al
                          </span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((item, i) => (
                        <>
                          <tr>
                            <td className='border-2 border-gray-300'>
                              <div className="m-auto w-24">
                                <input
                                  className={`text-lg text-center h-16 pl-4 bg-transparent w-24 focus:outline-none ${errors.shipmentPktDetails?.[i]?.quantity ? 'border-l-4 border-red-500' : ''}`}
                                  type='number'
                                  defaultValue={1}
                                  {...register(`shipmentPktDetails.${i}.quantity`, { required: true })}
                                />
                              </div>
                            </td>
                            <td className='border-2 border-gray-300'>
                              <div className="m-auto w-24">
                                <input
                                  className={`text-lg text-center h-16 pl-4 bg-transparent w-14 focus:outline-none ${errors.shipmentPktDetails?.[i]?.weight ? 'border-l-4 border-red-500' : ''}`}
                                  type='number'
                                  {...register(`shipmentPktDetails.${i}.weight`)}
                                />
                                <span className='text-sm text-gray-400 font-bold mr-1'>KG</span>
                              </div>
                            </td>
                            <td className='border-2 border-gray-300 lg:px-2'>
                              <div className="m-auto w-52">
                                <input
                                  className={`text-lg sm:p-0 text-center h-16 bg-transparent w-12 focus:outline-none ${errors.shipmentPktDetails?.[i]?.longShip ? 'border-l-4 border-red-500' : ''}`}
                                  type='number'
                                  {...register(`shipmentPktDetails.${i}.longShip`)}
                                />
                                <span className='text-sm text-gray-400 font-bold lg:mr-1 w-4'>X</span>
                                <input
                                  className={`text-lg sm:p-0 text-center h-16 bg-transparent w-12 focus:outline-none ${errors.shipmentPktDetails?.[i]?.widthShip ? 'border-l-4 border-red-500' : ''}`}
                                  type='number'
                                  {...register(`shipmentPktDetails.${i}.widthShip`)}
                                />
                                <span className='text-sm text-gray-400 font-bold lg:mr-1 w-4'>X</span>
                                <input
                                  className={`text-lg text-center sm:p-0 h-16 bg-transparent w-12 focus:outline-none ${errors.shipmentPktDetails?.[i]?.highShip ? 'border-l-4 border-red-500' : ''}`}
                                  type='number'
                                  {...register(`shipmentPktDetails.${i}.highShip`)}
                                />
                                <span className='text-base text-gray-400 font-bold w-4'>CM</span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <span className='text-red-600 font-semibold'>{
                                errors.shipmentPktDetails?.[i]?.quantity?.type === 'typeError' ?
                                  'Cantidad paq. Valor no permitido' :
                                  errors.shipmentPktDetails?.[i]?.quantity?.message}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <span className='text-red-600 font-semibold'>{
                                errors.shipmentPktDetails?.[i]?.weight?.type === 'typeError' ?
                                  'Peso paq. Valor no permitido' :
                                  errors.shipmentPktDetails?.[i]?.weight?.message}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <span className='text-red-600 font-semibold'>{
                                errors.shipmentPktDetails?.[i]?.longShip?.type === 'typeError' ?
                                  'Largo paq. Valor no permitido' :
                                  errors.shipmentPktDetails?.[i]?.longShip?.message}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <span className='text-red-600 font-semibold'>{
                                errors.shipmentPktDetails?.[i]?.widthShip?.type === 'typeError' ?
                                  'Anchura paq. Valor no permitido' :
                                  errors.shipmentPktDetails?.[i]?.widthShip?.message}
                              </span>

                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <span className='text-red-600 font-semibold'>{
                                errors.shipmentPktDetails?.[i]?.highShip?.type === 'typeError' ?
                                  'Altura paq. Valor no permitido' :
                                  errors.shipmentPktDetails?.[i]?.highShip?.message}
                              </span>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <input
                  type="submit"
                  value="Cotizar"
                  disabled={numberOfPackets === 0 || Object.keys(errors).length >= 1}
                  className={`bg-primary-inabit-red mt-8 py-4 text-2xl text-white rounded-xl w-full ${(numberOfPackets === 0) || Object.keys(errors).length >= 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-inabit-coffe'}`}
                />
              </form>
            </div>
        }

        {/* Table of services */}
        {
          calculatorResult.length > 0 && calculatorControls.actionQuote
            ?
            <div className="grid-col-1 block sm:hidden lg:block">
              <div className="p-2">
                <div className="relative md:mb-8 text-center">
                  <h3 className="text-xl font-bold text-center pt-2 mb-8 uppercase">Servicios disponibles</h3>
                  <button className="relative md:absolute md:top-0 md:right-10 bg-primary-inabit-blue hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded-2xl inline-flex items-center" onClick={retryQuote}>
                    <span className='mr-4 text-white'>Volver a Cotizar</span>
                    <Image src={CalculatoSvg} rounded width="25" />
                  </button>
                  <hr className="md:mb-10 md:mt-6 md:min-w-full" />
                </div>
                <div className="grid grid-cols-1 overflow-auto ">
                  <div className="grid grid-flow-col-dense grid-cols-6 invisible md:visible text-center">
                    <div className="grid border border-slate-600 py-2 font-bold uppercase">Paquetería</div>
                    <div className="grid border border-slate-600 py-2 font-bold uppercase">Servicio</div>
                    <div className="grid border border-slate-600 py-2 font-bold uppercase">Entrega estimada</div>
                    <div className="grid border border-slate-600 py-2 font-bold uppercase">Entrega</div>
                    <div className="grid border border-slate-600 py-2 font-bold uppercase">Precio</div>
                    <div className="grid border border-slate-600 py-2 font-bold uppercase"></div>
                  </div>
                  {
                    calculatorResult.map((item: any, i: any) => (
                      <div className="grid grid-cols-1 md:grid-cols-6 text-center hover:bg-gray-100 border border-slate-600 rounded-3xl my-4 shadow-sm md:border-b md:m-0 md:shadow-none md:rounded-none bg-white md:bg-transparent" key={i}>
                        <div className="grid md:border md:border-slate-600 py-2">
                          <Image id='logoCompany' src={carrierImage[item.carrier]} rounded width="60" />
                        </div>
                        <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className="flex text-center ">
                            <span className='block md:hidden uppercase font-bold mr-1'>Servicio: </span>
                            <span className='text-sm'>{item.serviceName.replace('FedEx', 'In a bit')}</span>
                          </div>
                        </div>
                        <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className="flex text-center">
                            <span className="block md:hidden uppercase font-bold mr-1">Entrega estimada:</span>
                            <span className='text-sm'>{item.promiseDate || '----'}</span>
                          </div>
                        </div>
                        <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className="flex text-center">
                            <span className="block md:hidden uppercase font-bold mr-1">Entrega:</span>
                            <span className='text-sm'>En domicilio</span>
                          </div>
                        </div>
                        <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className="flex text-center">
                            <span className="block md:hidden uppercase font-bold mr-1">Precio:</span>
                            <span className='text-sm'>{`${item.clientPrice?.toFixed(2)} ${item.currency}`}</span>
                          </div>
                        </div>
                        <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className='w-full'>
                            <button
                              className="w-5/6 bg-primary-inabit-red text-white font-bold py-2 px-4 rounded-full hover:bg-primary-inabit-coffe"
                              onClick={() => onHandleSignUp()}
                            >
                              Crear guía
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            :
            calculatorControls.actionQuote && calculatorResult.length <= 0
              ?
              <div className='content-center text-center text-center p-8'>
                <div className="relative mb-8">
                  <button className="absolute top-0 right-10 bg-primary-inabit-blue hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded-2xl inline-flex items-center" onClick={retryQuote}>
                    <span className='mr-4 text-white'>Volver a Cotizar</span>
                    <Image src={CalculatoSvg} rounded width="25" />
                  </button>
                  <h3 className="text-xl font-bold text-center pt-2 mb-8 uppercase">Sin servicios disponibles</h3>
                  <hr className="mb-8 md:min-w-full" />
                </div>
                <div className="text-center">
                  <Image src={DeliveryTruck} rounded width="150" className='m-auto' />
                  <span className="font-bold">No hay servicios disponibles para esta área en estos momentos, vuelve a intentar más tarde</span>
                </div>
              </div>
              :
              <></>
        }
      </div >
    </div >
  )
}

const withConnect = connect(
  selectState(
    'publicCalculator.calculatorControls',
    'publicCalculator.calculatorResult',
    'app.profile'
  ),
  actionProps({ ...publicCalculatorActions }),
);

export default withRouter(withConnect(PublicCalculator));
