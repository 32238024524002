import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../commons/reducer';
import { post } from '../../utils/api';
import { calculatorActions, CALCULATOR } from './reducer';

function* getQuote() {
  yield takeLatest(CALCULATOR.GET_QUOTE, function* fn(action: any): any {
    const { calculatorForm } = action;
    const body = {
      clientAddrOrig: {
        zipCode: calculatorForm.clientAddrOrig.zipCode,
        colonyName: calculatorForm.clientAddrOrig.colonyName,
        city: calculatorForm.origData.find(
          (e: any) => e.colonyName === calculatorForm.clientAddrOrig.colonyName,
        )?.city,
      },
      clientAddrDest: {
        zipCode: calculatorForm.clientAddrDest.zipCode,
        colonyName: calculatorForm.clientAddrDest.colonyName,
        city: calculatorForm.destData.find(
          (e: any) => e.colonyName === calculatorForm.clientAddrDest.colonyName,
        )?.city,
      },
      shipmentDetails: [...calculatorForm.shipmentPktDetails],
      pickupOrder: calculatorForm.pickupOrder,
      acknowledgmentXT: calculatorForm.acknowledgmentXT,
      pickupOrderDate: calculatorForm.pickupDate,
      isResidency: calculatorForm.isResidency,
      ocurreService: calculatorForm.ocurreService,
    };
    if (calculatorForm.shipmentEnvDetails)
      body.shipmentDetails.push(calculatorForm.shipmentEnvDetails);
    try {
      const response = yield post('/shipping/get-quotation', body);
      if (response.error) {
        yield put(
          calculatorActions.calculatorFailed({ response: response.errorMsg }),
        );
      } else {
        yield put(calculatorActions.calculatorSuccess(response));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}

function* getOccureBranchOffices() {
  yield takeLatest(
    CALCULATOR.GET_OCURRE_BRANCH_OFFICES,
    function* fn(action: any): any {
      const { branchOffices } = action;
      const shipmentDetails = [...branchOffices.shipmentPktDetails].map(e => ({
        highShip: e.highShip,
        longShip: e.longShip,
        quantity: e.quantity,
        weight: e.quantity,
        widthShip: e.widthShip,
        type: 'PACKETS',
      }));
      const body = {
        dest: {
          country: 'MEXICO',
          state: branchOffices.destinationData.state,
          city: branchOffices.destinationData.city,
          population: branchOffices.destinationData.municipality,
          colonyName: branchOffices.destinationData.colonyName,
          postalCode: branchOffices.destinationData.postalCode,
        },
        shipmentDetails: shipmentDetails,
        pickupOrder: branchOffices.pickupOrder,
      };
      if (branchOffices.shipmentEnvDetails) {
        const data = {
          highShip: branchOffices.shipmentEnvDetails.highShip,
          longShip: branchOffices.shipmentEnvDetails.longShip,
          quantity: branchOffices.shipmentEnvDetails.quantity,
          weight: branchOffices.shipmentEnvDetails.quantity,
          widthShip: branchOffices.shipmentEnvDetails.widthShip,
          type: 'ENVELOPES',
        };
        body.shipmentDetails.push(data);
      }
      try {
        const response = yield post('/shipping/express-branch-offices', body);
        if (response.error) {
          yield put(
            calculatorActions.getOccureBranchOfficesFailed({
              response: response.errorMsg,
            }),
          );
        } else {
          yield put(calculatorActions.getOccureBranchOfficesSucess(response));
        }
      } catch {
        yield put(appActions.serverFailed('Oops, server failed'));
      }
    },
  );
}

function* getUpdatedRateForPickup() {
  yield takeLatest(
    CALCULATOR.GET_UPDATED_RATE_FOR_PICKUP,
    function* fn(action: any): any {
      const calculatorForm = action.updatedRateForPickup;
      const body = {
        clientAddrOrig: {
          zipCode: calculatorForm.clientAddrOrig.zipCode,
          colonyName: calculatorForm.clientAddrOrig.colonyName,
          city: calculatorForm.origData.find(
            (e: any) =>
              e.colonyName === calculatorForm.clientAddrOrig.colonyName,
          )?.city,
        },
        clientAddrDest: {
          zipCode: calculatorForm.clientAddrDest.zipCode,
          colonyName: calculatorForm.clientAddrDest.colonyName,
          city: calculatorForm.destData.find(
            (e: any) =>
              e.colonyName === calculatorForm.clientAddrDest.colonyName,
          )?.city,
        },
        shipmentDetails: [...calculatorForm.shipmentPktDetails],
        pickupOrder: calculatorForm.pickupOrder,
        acknowledgmentXT: calculatorForm.acknowledgmentXT,
        pickupOrderDate: calculatorForm.pickupDate,
        isResidency: calculatorForm.isResidency,
        ocurreService: calculatorForm.ocurreService,
        carrier: calculatorForm.shipmentSelect.carrier,
      };
      if (calculatorForm.shipmentEnvDetails)
        body.shipmentDetails.push(calculatorForm.shipmentEnvDetails);
      try {
        const response = yield post('/shipping/get-quotation-by-carrier', body);
        if (!response.success) {
          yield put(
            calculatorActions.getUpdatedRateForPickupFailed({
              response: response.errorMsg,
            }),
          );
        } else {
          yield put(calculatorActions.getUpdatedRateForPickupSuccess(response));

        }
      } catch {
        yield put(appActions.serverFailed('Oops, server failed'));
      }
    },
  );
}

export default function* saga(): any {
  yield spawn(getQuote);
  yield spawn(getOccureBranchOffices);
  yield spawn(getUpdatedRateForPickup);
}
