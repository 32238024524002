import Swal from 'sweetalert2';
import { get, post } from '../../utils/api';
import { appActions } from '../../commons/reducer';
import { takeLatest, spawn, put } from 'redux-saga/effects';
import { PICKUP, pickupActions } from './reducer';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function* pickupList() {
  yield takeLatest(PICKUP.GET_PICKUP_LIST, function* fn(action: any): any {
    try {
      const response = yield get('/shipping/pickups');
      if (!response.success) {
        yield put(
          pickupActions.getPickupListFailed({
            response: response.errorMsg,
          }),
        );
      } else {
        yield put(pickupActions.getPickupListSuccess(response.data));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}

function* createPickup() {
  yield takeLatest(PICKUP.CREATE_PICKUP, function* fn(action: any): any {
    const { pickupForm } = action;
    try {
      const body = {
        ...pickupForm,
        origin: {
          companyName: pickupForm.companyName,
          clientName: pickupForm.contactName,
          phoneNumber: pickupForm.contactPhone,
          streetName: pickupForm.address,
          streetNumber: pickupForm.intNumber,
          colonyName: pickupForm.colonyName,
          stateCode: pickupForm.state,
          city: pickupForm.city,
          postalCode: pickupForm.originZipCode,
        },
        shipmentDetails: [
          ...(Array.isArray(pickupForm?.shipmentPktDetails)
            ? pickupForm.shipmentPktDetails
                .map((e: any) => {
                  delete e.shpCode;
                  delete e.sequence;
                  return {
                    ...e,
                    highShip: Number(e.highShip),
                    longShip: Number(e.longShip),
                    quantity: Number(e.quantity),
                    weight: Number(e.weight),
                    widthShip: Number(e.widthShip),
                    productIdSAT: e.productIdSAT?.split('-')[1] ?? '',
                    description: e.productIdSAT?.split('-')[0] ?? '',
                  };
                })
                .filter(Boolean)
            : []),

          pickupForm?.shipmentEnvDetails
            ? {
                highShip: Number(pickupForm.shipmentEnvDetails.highShip),
                longShip: Number(pickupForm.shipmentEnvDetails.longShip),
                quantity: Number(pickupForm.shipmentEnvDetails.quantity),
                weight: Number(pickupForm.shipmentEnvDetails.weight),
                widthShip: Number(pickupForm.shipmentEnvDetails.widthShip),
              }
            : null,
        ].filter(e => e),
      };
      const response = yield post('/shipping/pickups', body);
      if (!response.success) {
        yield put(
          pickupActions.createPickupFailed({
            response: response.errorMsg,
          }),
        );
        MySwal.fire({
          icon: 'error',
          title: `Ooops, tenemos problemas para generar tu recolección`,
          text:
            JSON.parse(response.errorMsg).error.message ??
            response.error.message,
        });
      } else {
        MySwal.fire({
          icon: 'success',
          title: 'Tu recolección se agendó con éxito',
        });
        yield put(pickupActions.createPickupSuccess(response.data));
      }
    } catch (err) {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}

function* deletePickup() {
  yield takeLatest(PICKUP.DELETE_PICKUP, function* fn(action: any): any {
    const { deletePickup } = action;
    try {
      const body = {
        ...deletePickup,
      };
      const response = yield post('/shipping/pickups-cancel', body);
      if (!response.success) {
        yield put(
          pickupActions.deletePickupFailed({
            response: response.errorMsg,
          }),
        );
        MySwal.fire({
          icon: 'error',
          title: `Ooops, tenemos problemas para cancelar tu recolección`,
          text:
            JSON.parse(response.errorMsg).error.message ??
            response.error.message,
        });
      } else {
        MySwal.fire({
          icon: 'success',
          title: 'Tu recolección se canceló con éxito',
        });
        yield put(pickupActions.deletePickupSuccess(response.data));
      }
    } catch (err) {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}

export default function* saga(): any {
  yield spawn(pickupList);
  yield spawn(createPickup);
  yield spawn(deletePickup);
}
