import * as yup from 'yup';
import { connect } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { PkgDetail } from '../../../interfaces/general';
import { existPostalCode, getColonies } from '../../../utils/colonies';
import { pickupActions } from '../../../views/PickUps/reducer';
import { withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';

type ZipCodeEntry = { colonyName: string; city: string };

interface CreatePickupProps {
  actions?: any;
  pickupForm?: any;
  history?: any;
}

interface CreatePickupFormInputs {
  pickupForm: {
    address: string;
    contactName: string;
    companyName: string;
    contactEmail: string;
    contactPhone: string;
    intNumber: string;
    carrier: string;
    originZipCode: string;
    colonyName: string;
    state: string;
    city: string;
    country: string;
    pickupDate: string;
    isResidency: boolean;
    pickupStartTime: string;
    pickupEndTime: string;
    amountOfEnvelope: number;
    amountOfPackets: number;
    shipmentPktDetails: PkgDetail[];
    shipmentEnvDetails: PkgDetail | null;
  };
}

const CreatePickupSchema = yup.object({
  address: yup.string().trim(),
  contactName: yup.string().trim(),
  companyName: yup.string().trim(),
  contactEmail: yup.string().trim(),
  contactPhone: yup.string().trim(),
  intNumber: yup.string().trim(),
  carrier: yup.string().trim(),
  originZipCode: yup.string().trim(),
  colonyName: yup.string().trim(),
  state: yup.string().trim(),
  city: yup.string().trim(),
  country: yup.string().trim(),
  pickupDate: yup.string(),
  isResidency: yup.boolean().optional(),
  pickupStartTime: yup.string(),
  pickupEndTime: yup.string(),
  amountOfEnvelope: yup
    .number()
    .integer()
    .default(0)
    .min(0, 'Valor invalido: Rango de 0 - 1')
    .max(1, 'Solo se puede enviar 1 sobre en cada envió'),
  amountOfPackets: yup
    .number()
    .integer()
    .default(0)
    .min(0, 'Valor invalido: Rango de 0 - 25')
    .max(25, 'Solo se puede cotizar max 25 paquetes'),
  shipmentPktDetails: yup.array().of(
    yup.object({
      quantity: yup
        .number()
        .min(1, 'Min. cantidad de paquetes permitidos 1')
        .max(25, 'Max. cantidad de paquetes 25'),
      weight: yup
        .number()
        .min(1, 'Min. peso requerido es de 1KG')
        .max(20000, 'Max. peso soportado de 20000 kg'),
      longShip: yup.number().min(1, 'Min. 1 CM de largo permitido'),
      widthShip: yup.number().min(1, 'Min. 1 CM de Ancho permitido'),
      highShip: yup.number().min(1, 'Min. 1 CM de altura permitido'),
      shpCode: yup.string().required(),
    }),
  ),
  shipmentEnvDetails: yup
    .object({
      quantity: yup
        .number()
        .min(1, 'Min. cantidad permitida 1')
        .max(20, 'Max. cantidad permitida 20'),
      weight: yup.number().min(1).max(1),
      longShip: yup.number().min(2),
      widthShip: yup.number().min(2),
      highShip: yup.number().min(1.5).max(1.5),
      shpCode: yup.string(),
    })
    .nullable(),
});

const readyTimeOptions = [
  '08:00:00',
  '08:30:00',
  '09:00:00',
  '09:30:00',
  '10:00:00',
  '10:30:00',
  '11:00:00',
  '11:30:00',
  '12:00:00',
  '12:30:00',
  '13:00:00',
  '13:30:00',
  '14:00:00',
  '14:30:00',
];
const latestTimeOptions = [
  '15:00:00',
  '15:30:00',
  '16:00:00',
  '16:30:00',
  '17:00:00',
  '17:30:00',
  '18:00:00',
];

const SchedulePickup: React.FC<CreatePickupProps> = props => {
  const { actions, pickupForm, history } = props;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm<CreatePickupFormInputs>({
    resolver: yupResolver(CreatePickupSchema),
    defaultValues: {
      pickupForm: {
        address: '',
        contactName: '',
        companyName: '',
        contactEmail: '',
        contactPhone: '',
        intNumber: '',
        carrier: '',
        originZipCode: '',
        colonyName: '',
        state: '',
        city: '',
        country: 'MEXICO',
        pickupDate: '',
        isResidency: false,
        pickupStartTime: '',
        pickupEndTime: '',
        amountOfEnvelope: 0,
        amountOfPackets: 0,
        shipmentPktDetails: [],
        shipmentEnvDetails: null,
      },
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: 'pickupForm.shipmentPktDetails',
    control,
  });
  const numberOfPackets = watch('pickupForm.amountOfPackets');
  const numberOfEnvelopes = watch('pickupForm.amountOfEnvelope');
  const originZipCode = watch('pickupForm.originZipCode');
  const pickupDate = watch('pickupForm.pickupDate');
  const [coloniesOrig, setColoniesOrig] = useState<string[]>([]);
  const [states, setStates] = useState<any>([]);
  const [zipCodeResponse, setZipCodeResponse] = useState([]);

  useEffect(() => {
    const searchZipCode = async (zipcode: string, type: string) => {
      const res = await existPostalCode(zipcode);
      if (res.exists) {
        const data = getColonies(res.data);
        setZipCodeResponse(res.data);
        setColoniesOrig([...new Set(data)]);
        const uniqueStates = Array.from(
          new Map(
            res.data.map((item: any) => [
              item.stateCode,
              { key: item.stateCode, text: item.state, value: item.stateCode },
            ]),
          ).values(),
        );

        setStates(uniqueStates);
      }
    };

    if (originZipCode?.length === 5) searchZipCode(originZipCode, 'ORIGIN');
  }, [originZipCode, setValue]);

  useEffect(() => {
    const newValPkt = numberOfPackets || 0;
    const oldVal = fields.length;
    let envelopeDetail = {
      highShip: 1.5,
      longShip: 35,
      quantity: numberOfEnvelopes,
      sequence: 0,
      shpCode: '1',
      weight: 1,
      widthShip: 24,
    };
    if (numberOfEnvelopes > 0)
      setValue('pickupForm.shipmentEnvDetails', envelopeDetail);
    if (numberOfEnvelopes === 0)
      setValue('pickupForm.shipmentEnvDetails', null);
    if (newValPkt > oldVal) {
      for (let i = oldVal; i < newValPkt; i++) {
        append({
          sequence: 0,
          shpCode: '2',
          highShip: undefined,
          longShip: undefined,
          quantity: 1,
          weight: undefined,
          widthShip: undefined,
        });
      }
    } else {
      for (let i = oldVal; i > newValPkt; i--) {
        remove(i - 1);
      }
    }
  }, [append, fields, numberOfPackets, numberOfEnvelopes, remove, setValue]);

  const services = [
    {
      name: 'Purple bit',
      value: 'FEDEX',
    },
    {
      name: 'Yellow bit',
      value: 'DHL',
    },
    {
      name: 'Brown bit',
      value: 'UPS',
    },
  ];

  const onSubmit = (data: any) => {
    const cityFound = (zipCodeResponse as ZipCodeEntry[]).filter(
      origin => origin.colonyName === data.pickupForm.colonyName,
    );

    if (cityFound.length > 0) {
      data.pickupForm.city = cityFound[0].city;
    }
    return actions.createPickup({ ...data.pickupForm });
  };

  return (
    <>
      <Dimmer
        active={pickupForm.loading}
        inverted
      >
        <Loader inverted>Agendando recolección.....</Loader>
      </Dimmer>
      <div className='grid md:shadow-xl lg:p-4 sm:p-2 py-2 rounded-xl '>
        <div className='grid mb-4'>
          <h1 className='font-bold text-2xl text-center my-4'>
            Agenda una recolección
          </h1>
        </div>
        <form
          className='flex'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='flex flex-col w-full justify-between max-w-3xl'>
            <div>
              <select
                {...register('pickupForm.carrier', { required: true })}
                className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none  ${
                  errors.pickupForm?.carrier ? 'border-red-500' : ''
                }`}
                defaultValue=''
              >
                <option
                  key={''}
                  value={''}
                  disabled
                >
                  Selecciona una paquetería
                </option>
                {services?.map(form => (
                  <option
                    key={form.value}
                    value={form.value}
                  >
                    {form.name}
                  </option>
                ))}
              </select>
              <span className='text-red-600 font-semibold h-4'>
                {errors.pickupForm?.carrier?.message}
              </span>
            </div>
            <div className='flex flex-row justify-between mt-5 gap-10'>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>
                  Nombre de la persona
                </label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.contactName
                      ? 'border-2 border-red-600'
                      : ''
                  }`}
                  {...register('pickupForm.contactName')}
                  placeholder='Nombre completo'
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.contactName?.message}
                </span>
              </div>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>Compañía</label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.companyName
                      ? 'border-2 border-red-600'
                      : ''
                  }`}
                  {...register('pickupForm.companyName')}
                  placeholder='Nombre de la compañía'
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.companyName?.message}
                </span>
              </div>
            </div>
            <div className='flex flex-row justify-between mt-5 gap-10'>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>
                  Correo electrónico
                </label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.contactEmail
                      ? 'border-2 border-red-600'
                      : ''
                  }`}
                  {...register('pickupForm.contactEmail')}
                  placeholder='ejemplo@test.com'
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.contactEmail?.message}
                </span>
              </div>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>Teléfono</label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.contactPhone
                      ? 'border-2 border-red-600'
                      : ''
                  }`}
                  {...register('pickupForm.contactPhone')}
                  placeholder='5555-5555-55'
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.contactPhone?.message}
                </span>
              </div>
            </div>
            <div className='flex flex-row justify-between mt-5 gap-10'>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>Calle y número</label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.address ? 'border-2 border-red-600' : ''
                  }`}
                  {...register('pickupForm.address')}
                  placeholder='Calle y número'
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.address?.message}
                </span>
              </div>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>Número interior</label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.intNumber
                      ? 'border-2 border-red-600'
                      : ''
                  }`}
                  {...register('pickupForm.intNumber')}
                  placeholder='Int 123'
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.intNumber?.message}
                </span>
              </div>
            </div>
            <div className='flex flex-row justify-between mt-5 gap-10'>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>
                  Código postal de origen
                </label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.originZipCode
                      ? 'border-2 border-red-600'
                      : ''
                  }`}
                  {...register('pickupForm.originZipCode')}
                  placeholder='Código postal de origen'
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.originZipCode?.message}
                </span>
              </div>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>Colonia</label>
                <select
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full${
                    errors.pickupForm?.colonyName ? 'border-red-600' : ''
                  }`}
                  {...register('pickupForm.colonyName')}
                >
                  <option
                    hidden
                    value=''
                  >
                    Seleccionar Colonia
                  </option>
                  {coloniesOrig.map(option => (
                    <option
                      value={option}
                      key={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
                <span className='text-red-600 text-xs font-semibold h-5'>
                  {errors.pickupForm?.colonyName?.message}
                </span>
              </div>
            </div>
            <div className='flex flex-row justify-between mt-5 gap-10'>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>Estado</label>
                <select
                  {...register('pickupForm.state')}
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full${
                    errors.pickupForm?.state ? 'border-red-600' : ''
                  }`}
                  defaultValue=''
                >
                  <option
                    hidden
                    value=''
                  >
                    Seleccionar Estado
                  </option>
                  {states.map((state: any) => (
                    <option
                      key={state.key}
                      value={state.value}
                    >
                      {state.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>País</label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.country ? 'border-2 border-red-600' : ''
                  }`}
                  {...register('pickupForm.country')}
                  defaultValue='MEXICO'
                  disabled
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.country?.message}
                </span>
              </div>
            </div>
            <div className='flex flex-row justify-between mt-5 gap-10'>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>
                  Fecha de recolección
                </label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.pickupDate
                      ? 'border-2 border-red-600'
                      : ''
                  }`}
                  {...register('pickupForm.pickupDate')}
                  type='date'
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.pickupDate?.message}
                </span>
              </div>
              <div className='w-1/2 self-center'>
                <div className='flex flex-row align-middle items-center w-full'>
                  <input
                    className={`h-4 bg-white bg-transparent border-2 my-2 mr-5 text-lg rounded-lg ${
                      errors.pickupForm?.isResidency
                        ? 'border-2 border-red-600'
                        : ''
                    }`}
                    {...register('pickupForm.isResidency')}
                    placeholder='Int 123'
                    type='checkbox'
                  />
                  <label className='inline-block text-gray-800 text-center text-lg'>
                    ¿Es una residencia?
                  </label>
                </div>
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.isResidency?.message}
                </span>
              </div>
            </div>
            {pickupDate && (
              <div className='flex flex-row justify-between mt-5 gap-5'>
                <div className='w-1/2'>
                  <label className='mt-4 font-extrabold'>
                    Horario más temprano{' '}
                  </label>
                  <select
                    {...register('pickupForm.pickupStartTime')}
                    className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full${
                      errors.pickupForm?.pickupStartTime ? 'border-red-600' : ''
                    }`}
                    defaultValue=''
                  >
                    <option
                      hidden
                      value=''
                    >
                      Seleccionar horario
                    </option>
                    {readyTimeOptions.map((value: string) => (
                      <option
                        key={value}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                  <span className='text-red-600 font-semibold h-4'>
                    {errors.pickupForm?.pickupStartTime?.message}
                  </span>
                </div>
                <div className='w-1/2'>
                  <label className='mt-4 font-extrabold'>
                    Horario más tarde{' '}
                  </label>
                  <select
                    {...register('pickupForm.pickupEndTime')}
                    className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full${
                      errors.pickupForm?.pickupEndTime ? 'border-red-600' : ''
                    }`}
                    defaultValue=''
                  >
                    <option
                      hidden
                      value=''
                    >
                      Seleccionar horario
                    </option>
                    {latestTimeOptions.map((value: string) => (
                      <option
                        key={value}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                  <span className='text-red-600 font-semibold h-4'>
                    {errors.pickupForm?.pickupEndTime?.message}
                  </span>
                </div>
              </div>
            )}
            <div className='flex flex-row justify-between mt-5 gap-10'>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>
                  N° de sobres (Max. 1)
                </label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.amountOfEnvelope
                      ? 'border-2 border-red-600'
                      : ''
                  }`}
                  {...register('pickupForm.amountOfEnvelope')}
                  type='number'
                  defaultValue={0}
                  min={0}
                  max={1}
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.amountOfEnvelope?.message}
                </span>
              </div>
              <div className='w-1/2'>
                <label className='mt-4 font-extrabold'>
                  N° de paquetes (Max. 25)
                </label>
                <input
                  className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg w-full ${
                    errors.pickupForm?.amountOfPackets
                      ? 'border-2 border-red-600'
                      : ''
                  }`}
                  {...register('pickupForm.amountOfPackets')}
                  type='number'
                  max={25}
                  min={0}
                  defaultValue={0}
                />
                <span className='text-red-600 font-semibold h-4'>
                  {errors.pickupForm?.amountOfPackets?.message}
                </span>
              </div>
            </div>
            <div className='grid grid-cols-1 sm:w-full mt-5'>
              {isNaN(numberOfPackets) || numberOfPackets <= 0 ? (
                <></>
              ) : (
                <table className='table-auto text-center'>
                  <thead>
                    <tr>
                      <th className='border-2 border-gray-300 text-2xs h-16'>
                        <span className='uppercase'>N° de Paquetes</span>
                        <br />
                        <span className='text-gray-500'>Max. 25</span>
                      </th>
                      <th className='border-2 border-gray-300 text-2xs h-16'>
                        <span className='uppercase'>Peso por paquete</span>
                        <br />
                        <span className='text-gray-500'>KG</span>
                      </th>
                      <th className='border-2 border-gray-300 text-2xs h-16'>
                        <span className='uppercase'>
                          Dimensiones por paquete
                        </span>
                        <br />
                        <span className='text-gray-500'>L × An × Al</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((item, i) => (
                      <>
                        <tr key={i}>
                          <td className='border-2 border-gray-300'>
                            <div className='m-auto w-24'>
                              <input
                                className={`text-lg text-center h-16 pl-4 bg-transparent w-24 focus:outline-none ${
                                  errors.pickupForm?.shipmentPktDetails?.[i]
                                    ?.quantity
                                    ? 'border-l-4 border-red-500'
                                    : ''
                                }`}
                                type='number'
                                defaultValue={1}
                                max={25}
                                {...register(
                                  `pickupForm.shipmentPktDetails.${i}.quantity`,
                                  {
                                    required: true,
                                  },
                                )}
                              />
                            </div>
                          </td>
                          <td className='border-2 border-gray-300'>
                            <div className='m-auto w-24'>
                              <input
                                className={`text-lg text-center h-16 pl-4 bg-transparent w-14 focus:outline-none ${
                                  errors.pickupForm?.shipmentPktDetails?.[i]
                                    ?.weight
                                    ? 'border-l-4 border-red-500'
                                    : ''
                                }`}
                                type='number'
                                {...register(
                                  `pickupForm.shipmentPktDetails.${i}.weight`,
                                )}
                              />
                              <span className='text-sm text-gray-400 font-bold mr-1'>
                                KG
                              </span>
                            </div>
                          </td>
                          <td className='border-2 border-gray-300 lg:px-2'>
                            <div className='m-auto w-52'>
                              <input
                                className={`text-lg sm:p-0 text-center h-16 bg-transparent w-12 focus:outline-none ${
                                  errors.pickupForm?.shipmentPktDetails?.[i]
                                    ?.longShip
                                    ? 'border-l-4 border-red-500'
                                    : ''
                                }`}
                                type='number'
                                {...register(
                                  `pickupForm.shipmentPktDetails.${i}.longShip`,
                                )}
                              />
                              <span className='text-sm text-gray-400 font-bold lg:mr-1 w-4'>
                                X
                              </span>
                              <input
                                className={`text-lg sm:p-0 text-center h-16 bg-transparent w-12 focus:outline-none ${
                                  errors.pickupForm?.shipmentPktDetails?.[i]
                                    ?.widthShip
                                    ? 'border-l-4 border-red-500'
                                    : ''
                                }`}
                                type='number'
                                {...register(
                                  `pickupForm.shipmentPktDetails.${i}.widthShip`,
                                )}
                              />
                              <span className='text-sm text-gray-400 font-bold lg:mr-1 w-4'>
                                X
                              </span>
                              <input
                                className={`text-lg text-center sm:p-0 h-16 bg-transparent w-12 focus:outline-none ${
                                  errors.pickupForm?.shipmentPktDetails?.[i]
                                    ?.highShip
                                    ? 'border-l-4 border-red-500'
                                    : ''
                                }`}
                                type='number'
                                {...register(
                                  `pickupForm.shipmentPktDetails.${i}.highShip`,
                                )}
                              />
                              <span className='text-base text-gray-400 font-bold w-4'>
                                CM
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <span className='text-red-600 font-semibold'>
                              {errors.pickupForm?.shipmentPktDetails?.[i]
                                ?.quantity?.type === 'typeError'
                                ? 'Cantidad paq. Valor no permitido'
                                : errors.pickupForm?.shipmentPktDetails?.[i]
                                    ?.quantity?.message}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <span className='text-red-600 font-semibold'>
                              {pickupForm?.shipmentPktDetails?.[i]?.weight
                                ?.type === 'typeError'
                                ? 'Peso paq. Valor no permitido'
                                : pickupForm?.shipmentPktDetails?.[i]?.weight
                                    ?.message}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <span className='text-red-600 font-semibold'>
                              {errors.pickupForm?.shipmentPktDetails?.[i]
                                ?.longShip?.type === 'typeError'
                                ? 'Largo paq. Valor no permitido'
                                : errors.pickupForm?.shipmentPktDetails?.[i]
                                    ?.longShip?.message}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <span className='text-red-600 font-semibold'>
                              {pickupForm?.shipmentPktDetails?.[i]?.widthShip
                                ?.type === 'typeError'
                                ? 'Anchura paq. Valor no permitido'
                                : pickupForm?.shipmentPktDetails?.[i]?.widthShip
                                    ?.message}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <span className='text-red-600 font-semibold'>
                              {errors.pickupForm?.shipmentPktDetails?.[i]
                                ?.highShip?.type === 'typeError'
                                ? 'Altura paq. Valor no permitido'
                                : errors.pickupForm?.shipmentPktDetails?.[i]
                                    ?.highShip?.message}
                            </span>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className='grid grid-cols-1 my-6'>
              <input
                type='submit'
                value='Agendar recolección'
                className={`bg-primary-inabit-red py-2 text-2xl text-white rounded-xl ${
                  Object.keys(errors).length > 0
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-primary-inabit-coffe'
                }`}
              ></input>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const withConnect = connect(
  selectState('pickup.pickupForm', 'app.profile'),
  actionProps({ ...pickupActions }),
);

export default withRouter(withConnect(SchedulePickup));
