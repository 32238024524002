import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { actionProps, selectState } from '../../utils/reduxActions';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import Modal from '../../commons/Components/Modal';
import Tooltip from '@mui/material/Tooltip';
import SchedulePickUp from '../../commons/Components/SchedulePickUp';
import { formatDate } from '../../utils/functions';
import { pickupActions } from './reducer';
import Swal from 'sweetalert2';

interface PickupData {
  id: string;
  serviceName: string;
  serviceType: string;
  createdAt: string;
  pickupDate: string;
  pickupFolio: string;
}

interface PickupProps {
  actions?: any;
  pickupForm?: any;
  history?: any;
  deletePickup?: any;
  list?: PickupData[];
  profile?: any;
}

const PickUps: React.FC<PickupProps> = (props): JSX.Element => {
  let [isOpenModalSchedulePickup, setIsOpenModalSchedulePickup] =
    useState(false);
  const { actions, list, pickupForm, deletePickup } = props;

  useEffect(() => {
    actions.getPickupList();
  }, []);

  useEffect(() => {
    if (pickupForm.success) {
      setIsOpenModalSchedulePickup(false);
      actions.getPickupList();
    }
  }, [pickupForm.success, actions]);

  useEffect(() => {
    if (deletePickup.success) {
      actions.getPickupList();
    }
  }, [deletePickup.success, actions]);

  const openModal = () => {
    setIsOpenModalSchedulePickup(true);
  };

  const CustomToolbar = () => {
    return <GridToolbarContainer></GridToolbarContainer>;
  };

  const handlerCancelPickup = (data: any) => {
    Swal.fire({
      title: 'Desea cancelar la recolección?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cancelar!',
      cancelButtonText: 'No, abortar!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        Swal.showLoading();
        actions.deletePickup(data);
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Fecha de solicitud',
      minWidth: 160,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.createdAt,
      valueFormatter: (params: any) => formatDate(params.value),
    },
    {
      field: 'pickupFolio',
      headerName: 'Folio de recolección',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 140,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.pickupFolio,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 140,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) =>
        params.row?.status
          ? params.row?.status
              .toString()
              .replace('1', 'Agendada')
              .replace('2', 'Cancelada')
          : '-',
    },
    {
      field: 'serviceName',
      headerName: 'Paquetería',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 130,
      headerClassName: 'text-lg text-bold',
      valueGetter: ({ value }) =>
        value
          .replace('EXPRESS', 'Blue bit')
          .replace('FEDEX', 'Purple bit')
          .replace('DHL', 'Yellow bit')
          .replace('INABIT', 'Orange bit')
          .replace('UPS', 'Brown bit'),
    },
    {
      field: 'pickupDate',
      headerName: 'Fecha de recolección',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 130,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params?.row?.pickupDate || '',
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      sortable: false,
      minWidth: 100,
      renderCell: data => {
        return (
          <div>
            {data.row?.status === 1 && (
              <div className='grid grid-cols-4 grid-flow-col gap-1 grid-flow-row-dense'>
                <Tooltip
                  title={'Cancelar Recolección'}
                  arrow
                >
                  <div>
                    <button
                      className={`${'bg-red-500 hover:bg-red-600'} text-center p-2 text-white hover:text-white w-full h-full flex justify-center items-center rounded-lg`}
                      onClick={() => handlerCancelPickup(data.row)}
                      disabled={data.row.status === 0}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z'
                        />
                      </svg>
                    </button>
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='w-full overflow-auto'>
      <div className='flex flex-row-reverse'>
        <button
          type='button'
          onClick={() => openModal()}
          className='inline-flex float-right items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover transition ease-in-out duration-150 mb-4'
        >
          Agendar Recolección
        </button>
      </div>
      <Modal
        onClose={() => setIsOpenModalSchedulePickup(false)}
        isOpen={isOpenModalSchedulePickup}
        size='auto'
      >
        <SchedulePickUp />
      </Modal>
      <DataGrid
        autoHeight
        localeText={{
          toolbarDensity: 'Tamaño de Columnas',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Pequeño',
          toolbarDensityStandard: 'Medio',
          toolbarDensityComfortable: 'Grande',
          toolbarExport: 'Exportar',
          toolbarFilters: 'Filtros',
          toolbarColumns: 'Columnas',
        }}
        rows={list || []}
        columns={columns}
        pageSize={12}
        loading={pickupForm?.loading ?? false}
        className='rounded-xl'
        components={{ Toolbar: CustomToolbar }}
        density='comfortable'
      />
    </div>
  );
};

const withConnect = connect(
  selectState('pickup.pickupForm', 'pickup.list', 'pickup.deletePickup'),
  actionProps({ ...pickupActions }),
);

export default compose(withConnect)(PickUps);
