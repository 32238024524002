import { all, fork } from 'redux-saga/effects';

import app from '../commons/saga';
import Home from '../views/Home/saga';
import Shop from '../views/Shop/saga';
import WooCommerce from '../views/WooCommerce/saga';
import login from '../views/Login/saga';
import register from '../views/Register/saga';
import calculator from '../views/calculator/saga';
import ShipmentList from '../views/Shipments/saga';
import invoicesList from '../views/Invoices/saga';
import pickup from '../views/PickUps/saga';
import CompleteSignUp from '../views/CompleteSignUp/saga';
import addressBook from '../views/Address/AddressBook/saga';
import rechargeCredits from '../views/RechargeCredits/saga';
import Facturama from '../commons/Components/Facturama/saga';
import generateShipment from '../views/GenerateShipment/saga';
import updateddress from '../views/Address/UpdateAddress/saga';
import TransactionList from '../views/TransactionHistory/saga';
import createAddress from '../views/Address/CreateAddress/saga';
import stripeRechargeCredits from '../views/RechargeCredits/Stripe/saga';
import paypalRechargeCredits from '../views/RechargeCredits/Paypal/saga';
import speiRechargeCredits from '../views/RechargeCredits/Spei/saga';
import publicCalculator from '../views/Home/components/publicCalculator/saga';
import SupportPayment from '../commons/Components/SupportPayment/saga';

export function* rootSaga(): any {
  yield all([
    fork(WooCommerce),
    fork(Shop),
    fork(Home),
    fork(Facturama),
    fork(TransactionList),
    fork(publicCalculator),
    fork(ShipmentList),
    fork(CompleteSignUp),
    fork(paypalRechargeCredits),
    fork(stripeRechargeCredits),
    fork(speiRechargeCredits),
    fork(rechargeCredits),
    fork(generateShipment),
    fork(createAddress),
    fork(updateddress),
    fork(addressBook),
    fork(calculator),
    fork(register),
    fork(login),
    fork(app),
    fork(SupportPayment),
    fork(invoicesList),
    fork(pickup),
  ]);
}
