import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
const PATH = 'pickup/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

export const PICKUP = {
  GET_PICKUP_LIST: `${PATH}PICKUP_LIST`,
  GET_PICKUP_LIST_SUCCESS: `${PATH}PICKUP_LIST_SUCCESS`,
  GET_PICKUP_LIST_FAILED: `${PATH}PICKUP_LIST_FAILED`,
  CREATE_PICKUP: `${PATH}CREATE_PICKUP`,
  CREATE_PICKUP_SUCCESS: `${PATH}CREATE_PICKUP_SUCCESS`,
  CREATE_PICKUP_FAILED: `${PATH}CREATE_PICKUP_FAILED`,
  DELETE_PICKUP: `${PATH}DELETE_PICKUP`,
  DELETE_PICKUP_SUCCESS: `${PATH}DELETE_PICKUP_SUCCESS`,
  DELETE_PICKUP_FAILED: `${PATH}DELETE_PICKUP_FAILED`,
};

export const pickupActions = {
  pickupFormChanged: createAction(CONTROLS_CHANGED, 'controls'),
  getPickupList: createAction(PICKUP.GET_PICKUP_LIST),
  getPickupListSuccess: createAction(PICKUP.GET_PICKUP_LIST_SUCCESS, 'list'),
  getPickupListFailed: createAction(PICKUP.GET_PICKUP_LIST_FAILED, 'list'),
  createPickup: createAction(PICKUP.CREATE_PICKUP, 'pickupForm'),
  createPickupSuccess: createAction(
    PICKUP.CREATE_PICKUP_SUCCESS,
    'pickupResult',
  ),
  createPickupFailed: createAction(PICKUP.CREATE_PICKUP_FAILED, 'pickupResult'),
  deletePickup: createAction(PICKUP.DELETE_PICKUP, 'deletePickup'),
  deletePickupSuccess: createAction(
    PICKUP.DELETE_PICKUP_SUCCESS,
    'deletePickupResult',
  ),
  deletePickupFailed: createAction(
    PICKUP.DELETE_PICKUP_FAILED,
    'deletePickupResult',
  ),
};

const initialState = {
  pickupForm: {
    address: '',
    contactName: '',
    companyName: '',
    contactEmail: '',
    contactPhone: '',
    intNumber: '',
    carrier: '',
    originZipCode: '',
    colonyName: '',
    state: '',
    country: '',
    pickupDate: '',
    isResidency: false,
    pickupStartTime: '',
    pickupEndTime: '',
    amountOfEnvelope: '',
    amountOfPackets: '',
    shipmentPktDetails: [],
    shipmentEnvDetails: null,
    errorMessage: '',
    error: false,
    loading: false,
    success: null,
  },
  pickupResult: {},
  deletePickup: {
    errorMessage: '',
    error: false,
    loading: false,
    success: false,
  },
  deletePickupResult: {},
  list: [],
};

const pickupList = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.pickupForm = {
      ...state.pickupForm,
      ...action.pickupForm,
    };
  },
  [PICKUP.GET_PICKUP_LIST](state: any) {
    state.pickupForm.loading = true;
  },
  [PICKUP.GET_PICKUP_LIST_SUCCESS](state: any, action: any) {
    state.list = action.list;
    state.pickupForm.loading = false;
  },
  [PICKUP.GET_PICKUP_LIST_FAILED](state: any) {
    state.pickupForm.error = true;
    state.pickupForm.loading = false;
    state.pickupForm.errorMessage = 'Oops, wrong credentials.';
  },
  [PICKUP.CREATE_PICKUP](state: any, action: any) {
    state.pickupForm.loading = true;
  },
  [PICKUP.CREATE_PICKUP_SUCCESS](state: any, action: any) {
    state.pickupForm.loading = false;
    state.pickupForm = initialState.pickupForm;
    state.pickupForm.success = true;
  },
  [PICKUP.CREATE_PICKUP_FAILED](state: any) {
    state.pickupForm.error = true;
    state.pickupForm.loading = false;
    state.pickupForm.errorMessage = 'Oops, wrong credentials.';
  },
  [PICKUP.DELETE_PICKUP](state: any, action: any) {
    state.deletePickup.loading = true;
    state.deletePickup.success = false;
    state.deletePickup.errorMessage = '';
    state.deletePickup.error = false;
  },
  [PICKUP.DELETE_PICKUP_SUCCESS](state: any, action: any) {
    state.deletePickup.loading = false;
    state.deletePickup.success = true;
  },
  [PICKUP.DELETE_PICKUP_FAILED](state: any) {
    state.deletePickup.error = true;
    state.deletePickup.loading = false;
    state.deletePickup.errorMessage = 'Oops, wrong credentials.';
  },
  [APP.SERVER_FAILED](state: any) {
    state.pickupForm.loading = false;
  },
});

export default pickupList;
